/* eslint-disable */
import Swiper from 'swiper';
import 'expose-loader?$!jquery';
import 'jquery.scrollto';

$(document).ready(function() {
  function scrollToHashInURL(url, event) {
    const hashIndex = url.indexOf('#');
    if ( hashIndex > -1 ) {
      const targetElement = $('#' + url.substring(hashIndex + 1));
      if ( targetElement.length ) {
        if (event) event.preventDefault();
        $(window).scrollTo(targetElement, 500);
      }
    }
  }

  $('.header__link, .header__login-button').click(function(event){
    scrollToHashInURL($(this).attr('href'), event);
    $('.header__menu').removeClass('header__menu--open');
  });

  $('.header__toggle').click(function() {
    $('.header__menu').toggleClass('header__menu--open');
  });
});
